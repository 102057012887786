import Alert from '@hy-vee/web-core/lib/components/alert';

export const BonusPerkListAlert = ({isBonusPerkError, setIsBonusPerkError}) => (
    <Alert
        data-cy="bonus-perk-list-alert"
        onDismiss={() => {
            setIsBonusPerkError('');
        }}
        role="status"
        status="error">
        <p>
            {isBonusPerkError}
        </p>
    </Alert>
);
