import Spinner from '@hy-vee/web-core/lib/components/loading-spinner';

import styles from './loading-indicator.module.css';

const LoadingIndicator = () =>
    <div className={styles.loadingIndicator}>
        <Spinner />
    </div>;

export default LoadingIndicator;
